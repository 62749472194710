

<script setup lang="ts">
const props = defineProps<{
  to: string
  outline?: boolean
  classNames?: string
  disabled?: boolean
  small?: boolean
  dark?: boolean
}>()

const slots = useSlots()

const outlineClass
  = 'bg-transparent border border-black text-black hover:border-primary-400 hover:text-primary-400 active:border-primary-400 active:bg-primary-200 active:text-primary-600 focus:border-primary-600 focus:bg-primary-200 focus:text-primary-600'
</script>

<template>
  <NuxtLink
    :to="to"
    class="flex w-fit items-center gap-[8px] rounded-[20px] font-sans transition duration-200 ease-in-out"
    :class="[
      classNames,
      outline && !disabled ? outlineClass : '',
      small
        ? 'py-1 pl-2 pr-4 text-base font-bold leading-[26px]'
        : 'px-4 py-2 text-lg leading-[24px]',
      disabled
        ? 'cursor-not-allowed bg-dark/20'
        : ' hover:bg-primary-400 hover:text-white focus:bg-primary-600 active:bg-primary-600',
      dark ? 'bg-white text-dark' : 'bg-dark text-white',
    ]"
  >
    <span v-if="slots.icon">
      <slot name="icon" />
    </span>
    <span class="block">
      <slot />
    </span>
  </NuxtLink>
</template>
