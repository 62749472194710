import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowBack, LazySvgoArrowDropdown, LazySvgoArrowSlider, LazySvgoArticle, LazySvgoBmbfLogo, LazySvgoClose, LazySvgoCog, LazySvgoCrossareaFooter, LazySvgoCrossareaLogo, LazySvgoDfgCommunityLogo, LazySvgoDividerFooter, LazySvgoDossiers, LazySvgoExpandCircleDown, LazySvgoHamburger, LazySvgoHeadphones, LazySvgoLoadingRing, LazySvgoMic, LazySvgoPause, LazySvgoPlayCircle, LazySvgoQuoteIcon, LazySvgoSearch, LazySvgoUlLogo, LazySvgoUlLogoWhite, LazyRenderCacheable, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowBack", LazySvgoArrowBack],
["SvgoArrowDropdown", LazySvgoArrowDropdown],
["SvgoArrowSlider", LazySvgoArrowSlider],
["SvgoArticle", LazySvgoArticle],
["SvgoBmbfLogo", LazySvgoBmbfLogo],
["SvgoClose", LazySvgoClose],
["SvgoCog", LazySvgoCog],
["SvgoCrossareaFooter", LazySvgoCrossareaFooter],
["SvgoCrossareaLogo", LazySvgoCrossareaLogo],
["SvgoDfgCommunityLogo", LazySvgoDfgCommunityLogo],
["SvgoDividerFooter", LazySvgoDividerFooter],
["SvgoDossiers", LazySvgoDossiers],
["SvgoExpandCircleDown", LazySvgoExpandCircleDown],
["SvgoHamburger", LazySvgoHamburger],
["SvgoHeadphones", LazySvgoHeadphones],
["SvgoLoadingRing", LazySvgoLoadingRing],
["SvgoMic", LazySvgoMic],
["SvgoPause", LazySvgoPause],
["SvgoPlayCircle", LazySvgoPlayCircle],
["SvgoQuoteIcon", LazySvgoQuoteIcon],
["SvgoSearch", LazySvgoSearch],
["SvgoUlLogo", LazySvgoUlLogo],
["SvgoUlLogoWhite", LazySvgoUlLogoWhite],
["RenderCacheable", LazyRenderCacheable],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
