<script setup lang="ts">
import { WidgetsFloatingPlayer } from '#components'
import { Toaster } from '@/components/ui/sonner'
import queryFeatureFlags from '~~/graphql/queries/FeatureFlags/FeatureFlags.query.graphql'
import { useMediaStore } from '~~/stores/media'
import { useSearchStore } from '~~/stores/search'
import { useSiteStore } from '~~/stores/site'

import { storeToRefs } from 'pinia'
import { ModalsContainer } from 'vue-final-modal'
import type { FeatureFlagsData } from '~~/types/App'
import '@fontsource/alegreya/400.css'
import '@fontsource/alegreya/600.css'
import '@fontsource/alegreya/700.css'

// TODO: Remove this on Production

const { data } = await useAsyncQuery<FeatureFlagsData>(queryFeatureFlags)

const siteStore = useSiteStore()
const searchStore = useSearchStore()
const { searchQuery, searchArray } = storeToRefs(searchStore)
const { isSearchOpen, featureFlags } = storeToRefs(siteStore)
siteStore.setFeatureFlags(
  data.value?.globalSet ?? {
    toggle_user_login: true,
    toggle_reading_list: true,
    toggle_comment_system: true,
  },
)

const mediaStore = useMediaStore()
const { isVideoModalOpen } = storeToRefs(mediaStore)

const nuxtApp = useNuxtApp()
const config = useAppConfig()
const isLoading = ref(false)

nuxtApp.hook('page:start', () => {
  isLoading.value = true
})
nuxtApp.hook('page:finish', () => {
  isLoading.value = false
})
onMounted(() => {
  // eslint-disable-next-line no-console
  console.info(
    `🚀 CrossArea Frontend 🔢 Version: ${config.app.version}`,
    `🕙 BuildDate: ${new Date(config.app.buildtime).toLocaleString()}`,
  )
})

useSchemaOrg([
  // https://vue-schema-org.netlify.app/guide/guides/identity.html
  // @todo select appropriate identity
  // https://vue-schema-org.netlify.app/schema/website.html
  defineWebSite({
    name: 'CrossArea',
  }),
  // https://vue-schema-org.netlify.app/schema/webpage.html
  defineWebPage(),
])
</script>

<template>
  <div>
    <VitePwaManifest />
    <NuxtLoadingIndicator />
    <LoadingPageScreen />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <CookieControl locale="en" />
    <WidgetsFloatingPlayer />
    <ModalsContainer />
    <Toaster />
    <div class="fixed inset-x-0 top-0 z-[1000] flex gap-4 bg-black text-white">
      <span>searchQuery: {{ searchQuery }}</span>
      <span>searchArray: {{ searchArray }}</span>
    </div>
  </div>
</template>

<style scoped>
/* You can customise the default animation here. */

::view-transition-old(root) {
  animation: 90ms cubic-bezier(0.4, 0, 1, 1) both fade-out;
}

::view-transition-new(root) {
  animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in;
}
</style>

<style>
.cookieControl__ControlButton {
  bottom: 5rem;
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}

.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
