import { defineStore } from 'pinia'

interface SearchState {
  searchQuery: string
  searchArray: string[]
  searchHistory: string[]
}

export const useSearchStore = defineStore({
  id: 'search-stores',
  persist: {
    storage: persistedState.localStorage,
    beforeRestore: (context) => {
      // console.log('Before hydration...', context)
    },
    afterRestore: (context) => {
      // console.log('After hydration...')
    },
  },
  state: (): SearchState => {
    return {
      searchQuery: '',
      searchArray: [],
      searchHistory: [],
    }
  },
  getters: {
    getSearchQuery: (state: SearchState) => {
      return state.searchQuery
    },
    getSearchArray: (state: SearchState) => {
      return state.searchArray
    },
    getSearchHistory: (state: SearchState) => {
      return state.searchHistory
    },
  },
  actions: {
    setSearchQuery(query: string) {
      this.searchQuery = query
    },
    clearSearchQuery() {
      this.searchQuery = ''
    },
    pushSearchArray(query: string) {
      this.searchArray.push(query)
    },
    removeFromSearchArray(query: string) {
      this.searchArray = this.searchArray.filter(item => item !== query)
    },
    clearSearchArray() {
      this.searchArray = []
    },
  },
})
