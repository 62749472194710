import Lenis from 'lenis'
import { useScrollState } from '~/composables/useScrollState'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const { scrollState, setScrollState, setLenis } = useScrollState()

  nuxtApp.vueApp.provide('Lenis', Lenis)
  nuxtApp.vueApp.provide('setScrollState', setScrollState)
  nuxtApp.vueApp.provide('setLenis', setLenis)
  nuxtApp.vueApp.provide('scrollState', scrollState)
})
