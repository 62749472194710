import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()
  if (!sentry.dsn)
    return

  // Fix for ESM loader hook "The requested module 'vue' does not provide an export named 'computed'"
  globalThis._sentryEsmLoaderHookRegistered = true
  // Initialize Sentry
  Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: 'dark',
        showBranding: false,
      }),
    ],
    // Configure this whole part as you need it!
    tracesSampleRate: 0.2, // Change in prod
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://staging.crossarea.org'],
    replaysSessionSampleRate: 1.0, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  })
})
