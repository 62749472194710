import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/app/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/app/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_NxDDSatxox from "/app/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import plugin_vfCt6Q18Tc from "/app/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import titles_dw2T9lEw4h from "/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import motion_jmBwdqbgTJ from "/app/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import plugin_eTVJQYlCmx from "/app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_C2QvvtgUo3 from "/app/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import plugin_WLsn00x1qh from "/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_tMGwffvjBc from "/app/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/app/.nuxt/formkitPlugin.mjs";
import ssr_plugin_B4ptqVdIfe from "/app/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import plugin_AUP22rrBAc from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import a11y_client_0xrnkKCxbb from "/app/app/plugins/a11y.client.ts";
import dayjs_qC061yLsl3 from "/app/app/plugins/dayjs.ts";
import graphview_XsOWsyHqX2 from "/app/app/plugins/graphview.ts";
import leaflet_dNesIVEdaM from "/app/app/plugins/leaflet.ts";
import lenis_k5uZjrwGYE from "/app/app/plugins/lenis.ts";
import modal_bQkh0FeLzO from "/app/app/plugins/modal.ts";
import search_1ysbErLqs2 from "/app/app/plugins/search.ts";
import sentry_client_a4hAB0MOGi from "/app/app/plugins/sentry.client.ts";
import slider_qRqSUZJ8l9 from "/app/app/plugins/slider.ts";
import trpc_oDDHPNb90W from "/app/app/plugins/trpc.ts";
import virtualscroll_gaIR5e4hX4 from "/app/app/plugins/virtualscroll.ts";
import plugin_client_QHzhVBB2Ll from "/app/.nuxt/nuxt-booster/plugin.client.js";
import defaults_aRl2YLnTcb from "/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  session_client_NxDDSatxox,
  plugin_vfCt6Q18Tc,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  motion_jmBwdqbgTJ,
  plugin_eTVJQYlCmx,
  plugin_C2QvvtgUo3,
  plugin_WLsn00x1qh,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  plugin_tMGwffvjBc,
  formkitPlugin_pZqjah0RUG,
  ssr_plugin_B4ptqVdIfe,
  plugin_AUP22rrBAc,
  a11y_client_0xrnkKCxbb,
  dayjs_qC061yLsl3,
  graphview_XsOWsyHqX2,
  leaflet_dNesIVEdaM,
  lenis_k5uZjrwGYE,
  modal_bQkh0FeLzO,
  search_1ysbErLqs2,
  sentry_client_a4hAB0MOGi,
  slider_qRqSUZJ8l9,
  trpc_oDDHPNb90W,
  virtualscroll_gaIR5e4hX4,
  plugin_client_QHzhVBB2Ll,
  defaults_aRl2YLnTcb
]