import { defineStore } from 'pinia'

interface PlayerState {
  id: string
  title: string
  thumbnail: string
  mediaUrl: string
  timeToContinue: number
  isPlaying: boolean
  isMuted: boolean
  isFullscreen: boolean
  isVideoModalOpen: boolean
  isPiPActive: boolean
  mediaType: string
}

export const useMediaStore = defineStore({
  id: 'video-player-stores',
  state: (): PlayerState => ({
    id: '',
    title: '',
    thumbnail: '',
    mediaUrl: 'https://www.youtube.com/watch?v=VOS-XIIrzkA',
    timeToContinue: 0,
    isPlaying: false,
    isMuted: false,
    isFullscreen: false,
    isVideoModalOpen: false,
    isPiPActive: false,
    mediaType: 'video',
  }),
  actions: {
    setURL(url: string) {
      this.mediaUrl = url
    },
    setPlayerTitle(title: string) {
      this.title = title
    },
    setMediaType(type: string) {
      this.mediaType = type
    },
    registerPlayer(payload) {
      this.id = payload.id
      this.title = payload.title
      this.thumbnail = payload.thumbnail
      this.mediaUrl = payload.mediaUrl
      this.timeToContinue = 0
    },
    setPlayerTime(time: number) {
      this.timeToContinue = time
    },
    setPlay() {
      this.isPlaying = true
    },
    setPause() {
      this.isPlaying = false
    },
    togglePlay() {
      this.isPlaying = !this.isPlaying
    },
  },
})
