import { en } from '@formkit/i18n'
import { defineFormKitConfig } from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'
import { createAutoAnimatePlugin, createAutoHeightTextareaPlugin, createMultiStepPlugin } from '@formkit/addons'
import '@formkit/addons/css/multistep'

// main.js or formkit.config.ts
import '@formkit/themes/genesis'

const proPlugin = createProPlugin('fk-2efe21769c', inputs)

export default defineFormKitConfig({
  locales: { en },
  locale: 'en',
  plugins: [
    proPlugin,
    createMultiStepPlugin(),
    createAutoAnimatePlugin(),
    createAutoHeightTextareaPlugin(),
  ],
})
