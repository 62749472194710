<script lang="ts" setup>
import IconLogo from '~/assets/svgs/crossarea_logo.svg?component'

const { error } = defineProps({
  error: Object,
})

function handleError() {
  // Log the error details to a server-side log
  console.error(error)
  clearError({ redirect: '/' })
}
</script>

<template>
  <div
    class="container mx-auto mb-40 mt-20 grid grid-cols-4 md:grid-cols-12 md:gap-x-10"
  >
    <div
      class="order-1 col-span-4 flex flex-col items-center justify-start rounded-[20px] bg-gray-25/20 p-10 md:order-2 md:col-span-6 md:col-start-4"
    >
      <NuxtLink class="mb-10 flex w-full items-center justify-center" to="/">
        <IconLogo class="w-[250px] text-black" />
      </NuxtLink>
      <h1 class="mb-10 text-center text-h3">
        Oops! Something went wrong.
      </h1>
      <p class="mb-10 text-center text-xs text-dark/50">
        {{ error.message }}
      </p>
      <p>
        We're sorry for the inconvenience. Our team has been notified and will
        look into the issue.
      </p>
      <details>
        {{ error }}
      </details>
      <div class="mt-20 flex gap-5">
        <GenericButton to="/" @click.prevent="handleError">
          Go Back
        </GenericButton>
        <GenericButton to="/">
          Home
        </GenericButton>
      </div>
    </div>
  </div>
</template>
